

import {defineComponent, onMounted} from "vue";
import GoBackIcon from "@/components/system/GoBackIcon.vue";
import TeacherInformation from "@/components/system/TeacherInformation.vue";
import LogoutIcon from "@/components/system/LogoutIcon.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import {Row, Col, Select,Form} from 'ant-design-vue'
import {classListBill} from "@/views/conference/ClassList/ClassList";
export default defineComponent({
  name: "ClassList",
  components: {
    TimetableTitle,
    LogoutIcon,
    TeacherInformation,
    GoBackIcon,
    ARow: Row,
    ACol: Col,
    ASelect:Select,
    ASelectOption:Select.Option,
    AForm:Form,
    AFormItem: Form.Item
  },
  setup(){
    const {
      refData,
      init,
      onClick,
      getCourseList,
      filters,
    } = classListBill();

    onMounted(async () => {
      await init();
    })

    return {
      ...refData,
      onClick,
      filters,
      getCourseList,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
